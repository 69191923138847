import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAddItemPopup } from '../../../hooks/useAddItemPopup'
import { GetItemInfo } from '../../../store/gallery/actions'
import { TGalleryItemInfo } from '../../../store/gallery/types'
import { Gem } from '../../../svg/Gem'
import { IconFileAlt } from '../../../svg/IconFileAlt'
import { IconLike } from '../../../svg/IconLike'
import { IconVideo } from '../../../svg/IconVideo'
import { API_ROUTES } from '../../../utils/apiRoutes'
import { convertToDollars } from '../../../utils/convertToDollars'
import { addToFavoritesAction, addToRecommendedAction } from '../../../utils/galleryUtils/itemsActions'
import { addNewParams } from '../../../utils/helpers'
import { ItemTableStatus } from './itemLabels'
import { IconStar } from '../../../svg/IconStar'
import { recMode } from '../../../utils/consts'
import { AppStore } from '../../../store/applicationState'

interface GalleryCardsProps {
  setPurchasePopupActive: (value: boolean) => void
  setCurrentItem: (value: any) => void
  isCatalog: boolean
  i: number
  item: TGalleryItemInfo
  tenderId: number | undefined
}

export const TableItem: React.FC<GalleryCardsProps> = ({
  i,
  item,
  tenderId,
  isCatalog,
  setPurchasePopupActive,
  setCurrentItem,
}) => {
  const [imgError, setImgError] = useState(false)

  const dispatch = useDispatch()
  const { gallery, userData } = useSelector((store: AppStore) => store)
  const { add } = useAddItemPopup()

  const openItemPopup = (bidFromTable: number) => {
    dispatch(
      GetItemInfo.request({
        itemId: item.item_id,
        callBack: (success, data) => {
          if (success) {
            const finalized = false
            add({ item: data, isCatalog, bidFromTable, finalized })
            addNewParams('item', `${item.item_id}`)
          }
        },
      })
    )
  }

  const getItemStyle = (index: number) => {
    if (item.is_sold && item.bid_status_id === 7) {
      return 'SAMPLE BIDNULL bg-yellow-50 bg-opacity-30'
    }
    if (item.is_sold && item.bid_status_id === 0) {
      return 'SAMPLE SOLD bg-red-50 bg-opacity-30'
    }
    if (item.is_sold && item.bid_status_id === 8) {
      return 'SAMPLE BOUGHT bg-green-50 bg-opacity-40'
    }
    if (!item.is_sold && item.bid_status_id === 1) {
      return 'SAMPLE BID bg-blue-50 bg-opacity-50'
    }

    return index % 2 === 0 ? ' bg-bluegray-50 bg-opacity-50' : ''
  }

  const itemStatus = item.bid_status_id === 8 || !item.is_sold ? 'open' : ''

  const getSmallText = (text: string) => {
    if (text) {
      return <div className={`${text.length > 8 ? 'text-xs leading-none whitespace-normal' : ''}`}>{text}</div>
    }

    return ''
  }

  return (
    <tr className={`gallery_tr ${getItemStyle(i)}`}>
      <td className={`gallery_td ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        <button
          type='button'
          className='favorites text-blue-200'
          onClick={() => addToFavoritesAction(item.in_favorites, tenderId || 0, item.item_id, dispatch)}
        >
          <IconLike isActive={item.in_favorites} />
        </button>
      </td>

      <td className={`gallery_td ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        <div className='flex space-x-5'>
          <Link
            to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
            onClick={(e) => {
              if (e.nativeEvent.which === 1) {
                e.preventDefault()
                openItemPopup(0)
              }
            }}
            className='video text-bluegray-400 detail'
          >
            <IconVideo />
          </Link>

          <Link
            to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
            onClick={(e) => {
              if (e.nativeEvent.which === 1) {
                e.preventDefault()
                openItemPopup(0)
              }
            }}
            className='report text-bluegray-400 detail'
          >
            <IconFileAlt />
          </Link>
        </div>
      </td>

      <td className={`gallery_td ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        <Link
          to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
          onClick={(e) => {
            if (e.nativeEvent.which === 1) {
              e.preventDefault()
              openItemPopup(0)
            }
          }}
          className='sku text-lightblue-600 hover:text-lightblue-900'
        >
          {item.product.sku}
        </Link>
      </td>

      <td className={`gallery_td relative ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        <div className='flex'>
          <Link
            to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
            onClick={(e) => {
              if (e.nativeEvent.which === 1) {
                e.preventDefault()
                openItemPopup(0)
              }
            }}
            className='item-image'
          >
            {!item.product_gallery.images[0] || imgError ? (
              <div className='text-white bg-bluegray-300 flex items-center justify-center w-12 max-w-xs text-4xl border border-bluegray-200'>
                <Gem />
              </div>
            ) : (
              <img
                src={item.product_gallery.images[0]}
                alt='Diamond'
                className='w-12 max-w-xs'
                onError={() => setImgError(true)}
              />
            )}
          </Link>
        </div>
        {((gallery.data.atLeastOneRecommended && item.in_recommendations) || recMode) && (
          <button
            type='button'
            disabled={!recMode}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              addToRecommendedAction(item.in_recommendations, userData.companyId!, item.item_id, dispatch)
            }}
            className='absolute top-0 right-2 recommendations disabled:opacity-100'
          >
            <IconStar className='!w-5 !h-5' isActive={item.in_recommendations} />
          </button>
        )}
      </td>

      <td className={`shape gallery_td ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        {getSmallText(item.product.product_details.shape)}
      </td>

      <td className={`weight gallery_td text-center ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        {item.product.product_details.weight}
      </td>

      <td className={`color gallery_td text-center ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        {getSmallText(item.product.product_details.color)}
      </td>

      <td className={`clarity gallery_td text-center ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        {getSmallText(item.product.product_details.clarity)}
      </td>

      <td className={`rap-list-price gallery_td text-center ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        {convertToDollars(item.product.rap_list_price) || ''}
      </td>

      {!isCatalog && (
        <td className={`price-per-carat gallery_td text-center ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
          {convertToDollars(item.product.asking_price_per_carat) || ''}
        </td>
      )}

      {!isCatalog ? (
        <td className='status gallery_td text-center w-96'>
          <ItemTableStatus
            item={item}
            tenderId={tenderId || 0}
            setPurchasePopupActive={setPurchasePopupActive}
            openItemPopup={(x) => openItemPopup(x)}
            setCurrentItem={setCurrentItem}
          />
        </td>
      ) : null}

      {/* <td className={`gallery_td text-center ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        {convertToDollars(item.product.asking_price) || ''}
      </td> */}

      <td className={`cut gallery_td text-center ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        {getSmallText(item.product.product_details.cut)}
      </td>

      <td className={`polish gallery_td text-center ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        {getSmallText(item.product.product_details.polish)}
      </td>

      <td className={`symmetry gallery_td text-center ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
        {getSmallText(item.product.product_details.symmetry)}
      </td>

      <td
        className={`circa-comments px-5 py-1 text-xxs text-bluegray-600 border border-bluegray-100 tracking-normal ${
          itemStatus === 'open' ? '' : 'opacity-50'
        }`}
      >
        {item.product.product_details.circa_comments}
      </td>
      {recMode && (
        <>
          <td className={`symmetry gallery_td text-center bg-orange-50 ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
            {item.product.count_of_bids || 0}
          </td>
          <td className={`symmetry gallery_td text-center bg-orange-50 ${itemStatus === 'open' ? '' : 'opacity-50'}`}>
            {getSmallText(item.product.shipping_owner || '')}
          </td>
        </>
      )}
    </tr>
  )
}
